// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Api from "../../../api/Api.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as Label from "../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as Events from "../../../libs/Events.res.js";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Checkbox from "../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Keyboard from "../../../libs/Keyboard.res.js";
import * as Textarea from "../../../styleguide/forms/Textarea/Textarea.res.js";
import * as IconClose from "../../../styleguide/icons/IconClose.res.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as TextField from "../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as ReactTelInput from "../../../bindings/ReactTelInput.res.js";
import * as TelephoneField from "../../../styleguide/forms/TelephoneField/TelephoneField.res.js";
import * as Routes_Location from "../../../routes/common/Routes_Location.res.js";
import * as Routes_Provider from "../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ContactProviderData from "./ContactProviderData.res.js";
import ReactGoogleRecaptcha from "react-google-recaptcha";
import * as ContactProviderEmailError from "./errors/ContactProviderEmailError.res.js";
import * as ContactProviderPhoneError from "./errors/ContactProviderPhoneError.res.js";
import * as ContactProviderModalScss from "./ContactProviderModal.scss";
import * as ContactProviderLastNameError from "./errors/ContactProviderLastNameError.res.js";
import * as ContactProviderAgreementError from "./errors/ContactProviderAgreementError.res.js";
import * as ContactProviderFirstNameError from "./errors/ContactProviderFirstNameError.res.js";
import * as ContactProviderCompanyNameError from "./errors/ContactProviderCompanyNameError.res.js";

var css = ContactProviderModalScss;

function ContactProviderModal(props) {
  var __locationName = props.locationName;
  var providerName = props.providerName;
  var leadType = props.leadType;
  var close = props.close;
  var recaptcha = props.recaptcha;
  var locationName = __locationName !== undefined ? __locationName : "";
  var initialState = React.useMemo((function () {
          return {
                  input: ContactProviderData.Input.empty(leadType, providerName, locationName),
                  results: undefined,
                  isSubmitted: false,
                  serverError: undefined,
                  recaptcha: null
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "Validate" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            results: ContactProviderData.Validate.all(state.input),
                            isSubmitted: state.isSubmitted,
                            serverError: state.serverError,
                            recaptcha: state.recaptcha
                          }
                        };
              case "Submitted" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            results: state.results,
                            isSubmitted: true,
                            serverError: state.serverError,
                            recaptcha: state.recaptcha
                          }
                        };
              case "BeforeSubmit" :
                  var x = state.recaptcha;
                  if (x === null) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              results: state.results,
                              isSubmitted: false,
                              serverError: state.serverError,
                              recaptcha: state.recaptcha
                            }
                          };
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              input: state.input,
                              results: state.results,
                              isSubmitted: true,
                              serverError: state.serverError,
                              recaptcha: state.recaptcha
                            },
                            _1: (function (param) {
                                param.dispatch("Send");
                              })
                          };
                  }
              case "AfterSubmit" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            results: state.results,
                            isSubmitted: false,
                            serverError: state.serverError,
                            recaptcha: state.recaptcha
                          }
                        };
              case "Send" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            results: ContactProviderData.Validate.all(state.input),
                            isSubmitted: state.isSubmitted,
                            serverError: state.serverError,
                            recaptcha: state.recaptcha
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              if (ContactProviderData.Validate.valid(Belt_Option.getExn(state.results))) {
                                return $$Promise.wait(Api.contactProvider(state.input), (function (x) {
                                              if (x.TAG === "Ok") {
                                                if (leadType === "Provider") {
                                                  return Url.visit(Routes_Provider.thankYou);
                                                } else {
                                                  return Url.visit(Routes_Location.thankYou);
                                                }
                                              }
                                              SentryLogger.error1({
                                                    rootModule: "ContactProviderModal",
                                                    subModulePath: /* [] */0,
                                                    value: "make",
                                                    fullPath: "ContactProviderModal.make"
                                                  }, "ContactProvider::Error", [
                                                    "Error",
                                                    x._0
                                                  ]);
                                              dispatch({
                                                    TAG: "ServerError",
                                                    _0: "Please enter correct data."
                                                  });
                                            }));
                              } else {
                                return dispatch("AfterSubmit");
                              }
                            })
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "Update" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: action._0,
                            results: state.results,
                            isSubmitted: state.isSubmitted,
                            serverError: state.serverError,
                            recaptcha: state.recaptcha
                          }
                        };
              case "ServerError" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            results: state.results,
                            isSubmitted: state.isSubmitted,
                            serverError: action._0,
                            recaptcha: state.recaptcha
                          },
                          _1: (function (param) {
                              param.dispatch("AfterSubmit");
                            })
                        };
              case "SetRecaptcha" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            results: state.results,
                            isSubmitted: state.isSubmitted,
                            serverError: state.serverError,
                            recaptcha: action._0
                          }
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          return Events.Subscriptions.subscribeToKeyDown(function ($$event) {
                      Keyboard.Dom.onEsc($$event, close);
                    });
        }), [close]);
  var error = state.serverError;
  var x = state.recaptcha;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      className: css.modalOverlay,
                      onClick: (function (param) {
                          close();
                        })
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(Control.make, {
                                    className: css.closeOverlayButton,
                                    onClick: (function (param) {
                                        close();
                                      }),
                                    children: JsxRuntime.jsx(IconClose.make, {
                                          title: "Close",
                                          size: "MD",
                                          color: "Gray"
                                        })
                                  }),
                              JsxRuntime.jsx("h2", {
                                    children: props.title
                                  }),
                              JsxRuntime.jsx("h3", {
                                    children: props.subtitle
                                  }),
                              error !== undefined ? JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx("p", {
                                            children: error
                                          }),
                                      className: css.error
                                    }) : null,
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "first_name",
                                                    children: "First name"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "first_name",
                                                    value: state.input.firstName,
                                                    status: Belt_Option.map(state.results, (function (x) {
                                                            if (x.firstName.TAG === "Ok") {
                                                              return "Valid";
                                                            } else {
                                                              return "Error";
                                                            }
                                                          })),
                                                    onChange: (function ($$event) {
                                                        var init = state.input;
                                                        dispatch({
                                                              TAG: "Update",
                                                              _0: {
                                                                firstName: $$event.target.value,
                                                                lastName: init.lastName,
                                                                companyName: init.companyName,
                                                                email: init.email,
                                                                phone: init.phone,
                                                                dialCountryCode: init.dialCountryCode,
                                                                country: init.country,
                                                                message: init.message,
                                                                isAgree: init.isAgree,
                                                                newsletterSubscription: init.newsletterSubscription,
                                                                providerName: init.providerName,
                                                                locationName: init.locationName,
                                                                leadType: init.leadType
                                                              }
                                                            });
                                                      })
                                                  }),
                                              JsxRuntime.jsx(ContactProviderFirstNameError.make, {
                                                    results: state.results
                                                  })
                                            ],
                                            className: css.firstName
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "last_name",
                                                    children: "Last name"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "last_name",
                                                    value: state.input.lastName,
                                                    status: Belt_Option.map(state.results, (function (x) {
                                                            if (x.lastName.TAG === "Ok") {
                                                              return "Valid";
                                                            } else {
                                                              return "Error";
                                                            }
                                                          })),
                                                    onChange: (function ($$event) {
                                                        var init = state.input;
                                                        dispatch({
                                                              TAG: "Update",
                                                              _0: {
                                                                firstName: init.firstName,
                                                                lastName: $$event.target.value,
                                                                companyName: init.companyName,
                                                                email: init.email,
                                                                phone: init.phone,
                                                                dialCountryCode: init.dialCountryCode,
                                                                country: init.country,
                                                                message: init.message,
                                                                isAgree: init.isAgree,
                                                                newsletterSubscription: init.newsletterSubscription,
                                                                providerName: init.providerName,
                                                                locationName: init.locationName,
                                                                leadType: init.leadType
                                                              }
                                                            });
                                                      })
                                                  }),
                                              JsxRuntime.jsx(ContactProviderLastNameError.make, {
                                                    results: state.results
                                                  })
                                            ],
                                            className: css.lastName
                                          })
                                    ],
                                    className: css.nameRow
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "company_name",
                                            children: "Company name"
                                          }),
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "company_name",
                                            value: state.input.companyName,
                                            status: Belt_Option.map(state.results, (function (x) {
                                                    if (x.companyName.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onChange: (function ($$event) {
                                                var init = state.input;
                                                dispatch({
                                                      TAG: "Update",
                                                      _0: {
                                                        firstName: init.firstName,
                                                        lastName: init.lastName,
                                                        companyName: $$event.target.value,
                                                        email: init.email,
                                                        phone: init.phone,
                                                        dialCountryCode: init.dialCountryCode,
                                                        country: init.country,
                                                        message: init.message,
                                                        isAgree: init.isAgree,
                                                        newsletterSubscription: init.newsletterSubscription,
                                                        providerName: init.providerName,
                                                        locationName: init.locationName,
                                                        leadType: init.leadType
                                                      }
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsx(ContactProviderCompanyNameError.make, {
                                            results: state.results
                                          })
                                    ],
                                    className: css.companyName
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "email",
                                            children: "Email"
                                          }),
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "email",
                                            value: state.input.email,
                                            status: Belt_Option.map(state.results, (function (x) {
                                                    if (x.email.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onChange: (function ($$event) {
                                                var init = state.input;
                                                dispatch({
                                                      TAG: "Update",
                                                      _0: {
                                                        firstName: init.firstName,
                                                        lastName: init.lastName,
                                                        companyName: init.companyName,
                                                        email: $$event.target.value,
                                                        phone: init.phone,
                                                        dialCountryCode: init.dialCountryCode,
                                                        country: init.country,
                                                        message: init.message,
                                                        isAgree: init.isAgree,
                                                        newsletterSubscription: init.newsletterSubscription,
                                                        providerName: init.providerName,
                                                        locationName: init.locationName,
                                                        leadType: init.leadType
                                                      }
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsx(ContactProviderEmailError.make, {
                                            results: state.results
                                          })
                                    ],
                                    className: css.email
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "phone",
                                            children: "Phone"
                                          }),
                                      JsxRuntime.jsx(TelephoneField.make, {
                                            id: "phone",
                                            value: state.input.phone,
                                            defaultCountry: "us",
                                            status: Belt_Option.map(state.results, (function (x) {
                                                    if (x.phone.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onChange: (function (value, selectedCountry) {
                                                var country = ReactTelInput.ReactTelInputData.countryFromJs(selectedCountry);
                                                var init = state.input;
                                                dispatch({
                                                      TAG: "Update",
                                                      _0: {
                                                        firstName: init.firstName,
                                                        lastName: init.lastName,
                                                        companyName: init.companyName,
                                                        email: init.email,
                                                        phone: "+" + (country.dialCode + Js_string.replace("+" + country.dialCode, "", value).trim()),
                                                        dialCountryCode: "+" + country.dialCode,
                                                        country: country.iso2.toUpperCase(),
                                                        message: init.message,
                                                        isAgree: init.isAgree,
                                                        newsletterSubscription: init.newsletterSubscription,
                                                        providerName: init.providerName,
                                                        locationName: init.locationName,
                                                        leadType: init.leadType
                                                      }
                                                    });
                                              }),
                                            preferredCountries: ["us"]
                                          }),
                                      JsxRuntime.jsx(ContactProviderPhoneError.make, {
                                            results: state.results
                                          })
                                    ],
                                    className: css.phone
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "message",
                                            children: "Message"
                                          }),
                                      JsxRuntime.jsx(Textarea.make, {
                                            id: "message",
                                            value: state.input.message,
                                            rows: 2,
                                            placeholder: "Ask anything you want",
                                            onChange: (function ($$event) {
                                                var init = state.input;
                                                dispatch({
                                                      TAG: "Update",
                                                      _0: {
                                                        firstName: init.firstName,
                                                        lastName: init.lastName,
                                                        companyName: init.companyName,
                                                        email: init.email,
                                                        phone: init.phone,
                                                        dialCountryCode: init.dialCountryCode,
                                                        country: init.country,
                                                        message: $$event.target.value,
                                                        isAgree: init.isAgree,
                                                        newsletterSubscription: init.newsletterSubscription,
                                                        providerName: init.providerName,
                                                        locationName: init.locationName,
                                                        leadType: init.leadType
                                                      }
                                                    });
                                              })
                                          })
                                    ],
                                    className: css.message
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs(Checkbox.WithLabel.make, {
                                            id: "agreementCheckbox",
                                            size: "MD",
                                            checked: state.input.isAgree,
                                            onChange: (function (_event) {
                                                var init = state.input;
                                                dispatch({
                                                      TAG: "Update",
                                                      _0: {
                                                        firstName: init.firstName,
                                                        lastName: init.lastName,
                                                        companyName: init.companyName,
                                                        email: init.email,
                                                        phone: init.phone,
                                                        dialCountryCode: init.dialCountryCode,
                                                        country: init.country,
                                                        message: init.message,
                                                        isAgree: !state.input.isAgree,
                                                        newsletterSubscription: init.newsletterSubscription,
                                                        providerName: init.providerName,
                                                        locationName: init.locationName,
                                                        leadType: init.leadType
                                                      }
                                                    });
                                              }),
                                            children: [
                                              "I agree to the ",
                                              JsxRuntime.jsx("a", {
                                                    children: "Terms & Conditions",
                                                    href: "/terms-of-use"
                                                  })
                                            ]
                                          }),
                                      JsxRuntime.jsx(ContactProviderAgreementError.make, {
                                            results: state.results
                                          })
                                    ],
                                    className: css.agreementCheckbox
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                          id: "newsletterCheckbox",
                                          size: "MD",
                                          checked: state.input.newsletterSubscription,
                                          onChange: (function (_event) {
                                              var init = state.input;
                                              dispatch({
                                                    TAG: "Update",
                                                    _0: {
                                                      firstName: init.firstName,
                                                      lastName: init.lastName,
                                                      companyName: init.companyName,
                                                      email: init.email,
                                                      phone: init.phone,
                                                      dialCountryCode: init.dialCountryCode,
                                                      country: init.country,
                                                      message: init.message,
                                                      isAgree: init.isAgree,
                                                      newsletterSubscription: !state.input.newsletterSubscription,
                                                      providerName: init.providerName,
                                                      locationName: init.locationName,
                                                      leadType: init.leadType
                                                    }
                                                  });
                                            }),
                                          children: "Receive latest industry news"
                                        }),
                                    className: css.agreementCheckbox
                                  }),
                              recaptcha !== undefined ? JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(ReactGoogleRecaptcha, {
                                            invisible: "true",
                                            sitekey: Belt_Option.getExn(Caml_option.valFromOption(recaptcha)),
                                            onChange: (function (value) {
                                                dispatch({
                                                      TAG: "SetRecaptcha",
                                                      _0: value
                                                    });
                                              })
                                          }),
                                      className: css.agreementCheckbox
                                    }) : null,
                              JsxRuntime.jsx(Button.make, {
                                    size: "LG",
                                    color: "Primary",
                                    visuallyDisabled: x === null ? true : false,
                                    busy: state.isSubmitted,
                                    className: css.sendButton,
                                    onClick: (function (param) {
                                        dispatch("BeforeSubmit");
                                      }),
                                    children: "Send Message"
                                  })
                            ],
                            className: css.modalContents
                          }),
                      className: Cx.cx([css.sideModal]),
                      id: "sideModal"
                    })
              ],
              className: Cx.cx([css.sideModalContainer]),
              id: "sideModalContainer"
            });
}

var Input;

var Validate;

var ValidationResult;

var ReactTelInputData;

var make = ContactProviderModal;

export {
  css ,
  Input ,
  Validate ,
  ValidationResult ,
  ReactTelInputData ,
  make ,
}
/* css Not a pure module */
