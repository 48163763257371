// extracted by mini-css-extract-plugin
export var address = "ProviderLocations__address__FKVNN";
export var agreementCheck = "ProviderLocations__agreementCheck__rh8Kq";
export var anchor = "ProviderLocations__anchor__KYEv4";
export var badge = "ProviderLocations__badge__Hnbst";
export var column = "ProviderLocations__column__jWX2v";
export var contactBtnWrapper = "ProviderLocations__contactBtnWrapper__gabXP";
export var details = "ProviderLocations__details__g0gxl";
export var detailsWrapper = "ProviderLocations__detailsWrapper__ZR29o";
export var facilities = "ProviderLocations__facilities__RMtBY";
export var iconWrapper = "ProviderLocations__iconWrapper__JrYTF";
export var image = "ProviderLocations__image__NM0Hy";
export var infoItem = "ProviderLocations__infoItem__uBJ9Y";
export var location = "ProviderLocations__location__zycS2";
export var locations = "ProviderLocations__locations__ML4Pe";
export var locationsWrapper = "ProviderLocations__locationsWrapper__opOMa";
export var logo = "ProviderLocations__logo__OdA0i";
export var name = "ProviderLocations__name__Mh5rw";
export var paginationBar = "ProviderLocations__paginationBar__WJ2ng";
export var paginationInfo = "ProviderLocations__paginationInfo___Oko8";
export var phoneItemWrapper = "ProviderLocations__phoneItemWrapper__G997E";
export var photo = "ProviderLocations__photo__Ncg2C";
export var productsInfo = "ProviderLocations__productsInfo__EdAmb";
export var provider = "ProviderLocations__provider__ky9vy";
export var providerLogo = "ProviderLocations__providerLogo__oJj7C";
export var row = "ProviderLocations__row__udXJs";
export var sponsored = "ProviderLocations__sponsored___PtTj";
export var sponsoredLabel = "ProviderLocations__sponsoredLabel__Jcku8";
export var title = "ProviderLocations__title__liPMX";
export var wrapper = "ProviderLocations__wrapper__OKNlq";