// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../styleguide/components/Link/A.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as User from "../../../models/User.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as Picture from "../../../styleguide/forms/Picture/Picture.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Spinner from "../../../styleguide/components/Spinner/Spinner.res.js";
import * as IconYear from "../../../styleguide/icons/IconYear.res.js";
import * as IconPhone from "../../../styleguide/icons/IconPhone.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Breadcrumb from "../../../styleguide/components/Breadcrumb/Breadcrumb.res.js";
import * as Pagination from "../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Api_Provider from "../../../api/providers/Api_Provider.res.js";
import * as IconBuilding from "../../../styleguide/icons/IconBuilding.res.js";
import * as IconProducts from "../../../styleguide/icons/IconProducts.res.js";
import * as LocationTile from "../../locations/common/location-tile/LocationTile.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as IconEmployees from "../../../styleguide/icons/IconEmployees.res.js";
import * as Routes_Location from "../../../routes/common/Routes_Location.res.js";
import * as Routes_Provider from "../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Global from "Client/common/global";
import * as ContactProviderModal from "../../provider-detail/contact-provider-modal/ContactProviderModal.res.js";
import * as ProviderLocationsScss from "./ProviderLocations.scss";

var css = ProviderLocationsScss;

function ProviderLocations$default(props) {
  var offset = props.offset;
  var totalPages = props.totalPages;
  var totalLocations = props.totalLocations;
  var showAgreementCheck = props.showAgreementCheck;
  var userLoginStatus = props.userLoginStatus;
  var provider = props.provider;
  var locations = props.locations;
  var container = React.useRef(null);
  var initialState = React.useMemo((function () {
          return {
                  locations: locations,
                  totalLocations: totalLocations,
                  totalPages: totalPages,
                  contactProviderModalVisible: false,
                  currentPage: 1,
                  fetching: false,
                  offset: offset
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "ShowContactProviderModal" :
                  return {
                          TAG: "Update",
                          _0: {
                            locations: state.locations,
                            totalLocations: state.totalLocations,
                            totalPages: state.totalPages,
                            contactProviderModalVisible: true,
                            currentPage: state.currentPage,
                            fetching: state.fetching,
                            offset: state.offset
                          }
                        };
              case "CloseModal" :
                  return {
                          TAG: "Update",
                          _0: {
                            locations: state.locations,
                            totalLocations: state.totalLocations,
                            totalPages: state.totalPages,
                            contactProviderModalVisible: false,
                            currentPage: state.currentPage,
                            fetching: state.fetching,
                            offset: state.offset
                          }
                        };
              case "FetchProviderLocations" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            locations: state.locations,
                            totalLocations: state.totalLocations,
                            totalPages: state.totalPages,
                            contactProviderModalVisible: state.contactProviderModalVisible,
                            currentPage: state.currentPage,
                            fetching: true,
                            offset: state.offset
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api_Provider.locations(param.state.currentPage, 15, provider.id, undefined), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "UpdateLocations",
                                                    _0: x._0
                                                  });
                                      } else {
                                        return SentryLogger.error1({
                                                    rootModule: "ProviderLocations",
                                                    subModulePath: /* [] */0,
                                                    value: "default",
                                                    fullPath: "ProviderLocations.default"
                                                  }, "Provider::FetchLocations::Error", [
                                                    "Error",
                                                    x._0
                                                  ]);
                                      }
                                    }));
                            })
                        };
              
            }
          } else {
            if (action.TAG === "Paginate") {
              return {
                      TAG: "UpdateWithSideEffects",
                      _0: {
                        locations: state.locations,
                        totalLocations: state.totalLocations,
                        totalPages: state.totalPages,
                        contactProviderModalVisible: state.contactProviderModalVisible,
                        currentPage: action._0,
                        fetching: state.fetching,
                        offset: state.offset
                      },
                      _1: (function (param) {
                          Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                  prim.scrollIntoView();
                                }));
                          param.dispatch("FetchProviderLocations");
                        })
                    };
            }
            var res = action._0;
            return {
                    TAG: "Update",
                    _0: {
                      locations: res.locations,
                      totalLocations: res.totalLocations,
                      totalPages: res.totalPages,
                      contactProviderModalVisible: state.contactProviderModalVisible,
                      currentPage: res.currentPage,
                      fetching: false,
                      offset: res.offset
                    }
                  };
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var phoneNumber = provider.phoneNumber;
  var tmp = phoneNumber === "" ? Global.DCContactNumber : phoneNumber;
  var tmp$1;
  if (state.fetching) {
    tmp$1 = JsxRuntime.jsx("div", {
          children: JsxRuntime.jsx(Spinner.make, {
                size: "XL",
                color: "Teal"
              }),
          className: css.spinnerList
        });
  } else {
    var tmp$2;
    if (state.totalPages < 2) {
      var match$1 = state.totalLocations;
      tmp$2 = match$1 !== 0 ? (
          match$1 !== 1 ? "Showing all" : "Showing 1"
        ) : "No locations found";
    } else {
      tmp$2 = "Showing " + (String(state.offset + 1 | 0) + (" to " + (String(state.offset + state.locations.length | 0) + (" of " + String(state.totalLocations)))));
    }
    tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx("div", {
                  children: Belt_Array.map(state.locations, (function ($$location) {
                          return JsxRuntime.jsx(LocationTile.make, {
                                      location: $$location,
                                      markSponsored: false,
                                      deferImage: true,
                                      userLoginStatus: User.LoginStatus.fromBool(userLoginStatus),
                                      showAgreementCheck: showAgreementCheck
                                    }, "location-tile--" + ID.toString($$location.id) + "--" + ID.toString(provider.id));
                        })),
                  className: css.locations
                }),
            JsxRuntime.jsxs("div", {
                  children: [
                    state.totalPages > 1 ? JsxRuntime.jsx(Pagination.make, {
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            onPageClick: (function (pageNum) {
                                dispatch({
                                      TAG: "Paginate",
                                      _0: pageNum
                                    });
                              })
                          }) : null,
                    JsxRuntime.jsx("div", {
                          children: tmp$2,
                          className: css.paginationInfo
                        })
                  ],
                  className: css.paginationBar
                })
          ]
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(Picture.make, {
                                          src: provider.logo,
                                          large: [
                                            200,
                                            100
                                          ],
                                          crop: "Fit"
                                        }),
                                    className: css.providerLogo
                                  }),
                              JsxRuntime.jsx("h1", {
                                    children: provider.name + " Data Center Locations",
                                    className: css.title
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: provider.hqFullAddress,
                                    className: css.address
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx(IconPhone.make, {
                                                        size: "SM",
                                                        color: "DarkGray"
                                                      }),
                                                  className: css.iconWrapper
                                                }),
                                            JsxRuntime.jsx("span", {
                                                  children: tmp
                                                })
                                          ],
                                          className: css.infoItem
                                        }),
                                    className: css.phoneItemWrapper
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs(A.make, {
                                          href: Routes_Provider.$$Location.index(provider.slug),
                                          className: css.infoItem,
                                          children: [
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx(IconBuilding.make, {
                                                        size: "SM",
                                                        color: "DarkGray"
                                                      }),
                                                  className: css.iconWrapper
                                                }),
                                            JsxRuntime.jsx("span", {
                                                  children: String(provider.totalLocations)
                                                }),
                                            "facilities"
                                          ]
                                        }),
                                    className: css.facilities
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(IconEmployees.make, {
                                                  size: "SM",
                                                  color: "DarkGray"
                                                }),
                                            className: css.iconWrapper
                                          }),
                                      JsxRuntime.jsx("span", {
                                            children: provider.numberEmployees
                                          }),
                                      "employees"
                                    ],
                                    className: css.infoItem
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(IconYear.make, {
                                                  size: "SM",
                                                  color: "DarkGray"
                                                }),
                                            className: css.iconWrapper
                                          }),
                                      JsxRuntime.jsx("span", {
                                            children: provider.yearFounded
                                          }),
                                      "year founded"
                                    ],
                                    className: css.infoItem
                                  }),
                              provider.totalProducts > 0 ? JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(IconProducts.make, {
                                                    size: "SM",
                                                    color: "DarkGray"
                                                  }),
                                              className: css.iconWrapper
                                            }),
                                        JsxRuntime.jsx("span", {
                                              children: String(provider.totalProducts)
                                            }),
                                        provider.totalProducts === 1 ? "product" : "products"
                                      ],
                                      className: css.infoItem
                                    }) : null,
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(Button.make, {
                                          size: "SM",
                                          color: "Primary",
                                          expanded: true,
                                          onClick: (function (param) {
                                              dispatch("ShowContactProviderModal");
                                            }),
                                          children: "Contact Provider"
                                        }),
                                    className: css.contactBtnWrapper
                                  })
                            ],
                            className: css.details
                          }),
                      className: css.detailsWrapper
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Breadcrumb.make, {
                                    breadcrumbLinks: [
                                      {
                                        linkName: "All Data Center Locations",
                                        linkPath: Routes_Location.index
                                      },
                                      {
                                        linkName: provider.name,
                                        linkPath: Routes_Provider.show(provider.slug)
                                      },
                                      {
                                        linkName: "Provider Locations",
                                        linkPath: Routes_Provider.$$Location.index(provider.slug)
                                      }
                                    ]
                                  }),
                              className: css.breadcrumbs
                            }),
                        tmp$1
                      ],
                      className: css.locationsWrapper
                    }),
                state.contactProviderModalVisible ? JsxRuntime.jsx(ContactProviderModal.make, {
                        recaptcha: Caml_option.some(props.recaptcha),
                        title: "Contact " + provider.name,
                        subtitle: "Send a message to get in touch with " + provider.name + ". Expect to receive an answer in 24 hours.",
                        close: (function () {
                            dispatch("CloseModal");
                          }),
                        leadType: "Provider",
                        providerName: provider.name
                      }) : null
              ],
              className: css.wrapper
            });
}

var $$default = ProviderLocations$default;

export {
  css ,
  $$default as default,
}
/* css Not a pure module */
