// Generated by ReScript, PLEASE EDIT WITH CARE


function show(slug) {
  return "/providers/" + encodeURI(slug);
}

function locations(slug) {
  return "/" + encodeURI(slug) + "-data-center-locations";
}

var awsLocations = locations("amazon-aws");

var microsoftLocations = locations("microsoft-azure");

var googleLocations = locations("google");

var equinixLocations = locations("equinix");

var digitalRealtyLocations = locations("digital-realty");

function show$1(providerSlug, countrySlug, stateSlug, citySlug) {
  return "/providers/" + encodeURI(providerSlug) + "/locations/" + encodeURI(countrySlug) + "/" + encodeURI(stateSlug) + "/" + encodeURI(citySlug);
}

var City = {
  show: show$1
};

function show$2(providerSlug, countrySlug) {
  return "/providers/" + encodeURI(providerSlug) + "/locations/" + encodeURI(countrySlug);
}

var Country = {
  show: show$2
};

function index(slug) {
  return "/" + (encodeURI(slug) + "-data-center-locations");
}

var $$Location = {
  index: index
};

function show$3(providerSlug, countrySlug, stateSlug) {
  return "/providers/" + encodeURI(providerSlug) + "/locations/" + encodeURI(countrySlug) + "/" + encodeURI(stateSlug);
}

var State = {
  show: show$3
};

function edit(slug) {
  return "/dashboard/providers/" + encodeURI(slug) + "/edit";
}

var Dashboard = {
  index: "/dashboard/providers",
  edit: edit,
  $$new: "/dashboard/providers/new"
};

var index$1 = "/providers";

var bareMetal = "/bare-metal-providers";

var colocation = "/colocation-providers";

var cloud = "/cloud-providers";

var internet = "/internet-providers";

var network = "/network-service-providers";

var thankYou = "/providers/thank_you";

export {
  index$1 as index,
  show ,
  locations ,
  bareMetal ,
  colocation ,
  cloud ,
  internet ,
  network ,
  awsLocations ,
  microsoftLocations ,
  googleLocations ,
  equinixLocations ,
  digitalRealtyLocations ,
  thankYou ,
  City ,
  Country ,
  $$Location ,
  State ,
  Dashboard ,
}
/* awsLocations Not a pure module */
