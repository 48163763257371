// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as ContactProviderModalScss from "../ContactProviderModal.scss";

var css = ContactProviderModalScss;

function ContactProviderFirstNameError(props) {
  var results = props.results;
  if (results === undefined) {
    return null;
  }
  var message = results.firstName;
  if (message.TAG === "Ok") {
    return null;
  } else {
    return JsxRuntime.jsx("div", {
                children: message._0,
                className: css.fieldError
              });
  }
}

var make = ContactProviderFirstNameError;

export {
  css ,
  make ,
}
/* css Not a pure module */
